.nutritional-content-table{
  padding:25px
}

.nutrition-top_title{
  padding-top: 70px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial,sans-serif;
  font-size: 50px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: .90;
  letter-spacing: normal;
  text-align: center;
  color: #acb53f;
}

.nutritional-top-step_title{
  font-family: 'Font Awesome 5 Free',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #2d2d2d;
  margin-top: 10px;
}

.nutritional-top-step-list-item{
    margin-bottom: 20px;
    margin-top: 20px;
    display: block;
    cursor: pointer;
    outline: none;
}

.nutritional-top-step-list-title-wrapper{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial,sans-serif;
  font-size: 15px;
  background-color: #acb53f;
  border-radius: 50px;
  color: white;
  display: flex;
  justify-content: center;
}

.context-text{
  font-family: 'Font Awesome 5 Free',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 15px;
    font-weight: 700;
    max-width: 500px;
    margin: 0 auto;
    letter-spacing: normal;
    line-height: 1.29;
    color: #100c08;
    text-align: center;
}

.table td,.table th{
  padding: 5px;
  border-top: 0;
  vertical-align: middle;
}

.ingridientRow{
  border-bottom: 1px solid #2d2d2d;
  height: 50px;
  cursor: pointer;
}

.ingridientRow td{
  text-align: center;
}

.ingridientRow.last{
   border-bottom: none;
}

  .group_title{
    background-color: #2d2d2d;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
    font-family: Norwester,sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    padding-left: 21px;
    padding: 10px !important;
    text-transform: uppercase;
}

.table_value{
    font-family: TradeGothic,sans-serif;
    font-size: 13px;
    font-weight: 700;
    opacity: 0;
    color: #100c08;
}

.green{
  background-color: #acb53f;
}

.table thead td div {
  font-family: TradeGothic,sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  color: #100c08;
  padding-top: 22px;
  padding-bottom: 22px;
}

.table tbody th div {
  font-family: TradeGothic,sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #100c08;
  padding: 5px;
}

.table tbody td div {
  font-family: TradeGothic,sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  color: #100c08;
  padding: 0px;
  /* width: 46px; */
}

.ingridientRow_title::before {
  content: "";
  display: inline-block;
  margin-right: 20px;
  margin-left: 20px;
  background-image: url('./plus.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 17px;
  height: 17px;
  position: relative;
  top: 2px;
}

.ingridientRow.active .ingridientRow_title:before {
  background-image: url('./cross.png');
}

.ingridientRow.active .table_value {
  opacity: 1;
}

/* Footer */
.ingridientRow.footer {
  border-top: 1px solid #2d2d2d;
  background-color: #100c08 !important;
  opacity: .8;
}

.total_table_value {
  font-family: TradeGothic, sans-serif;
  font-weight: 700;
  color: #ece9e9;
  width: 70px;
  text-align: center;
  font-size: 14px;
  line-height: 25px;
}

.total-title {
  font-size: 30px !important;
  color: #ece9e9 !important;
  padding-left: 21px !important;
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .total-title {
    padding-left: 10px;
    font-size: 15px !important;
  }

  .ingridientRow_title {
    text-align: center;
  }

  .total_table_value {
    font-size: 10px;
  }
}