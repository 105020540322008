.clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
  .clearfix{
    padding: 15px;
    margin-bottom: 10px;
  }
  /* a {
    color: #5D6975;
    text-decoration: underline;
  } */
  .td-column-function-even-example{
    font-weight: bold;
  color: green;
  }
  #logo {
    text-align: center;
    margin-bottom: 15px;
  }
  
  #logo img {
    width: 90px;
  }
  
  .invoiceh1 {
    border-top: 1px solid  #5D6975;
    border-bottom: 1px solid  #5D6975;
    color: #5D6975;
    font-size: 1.4em;
    line-height: 1.4em;
    font-weight: normal;
    text-align: left;
    margin: 0 0 20px 0;
  }
  
  #project {
    float: left;
    margin-top: 10px;
  }
  #secProject {
  
    float: right;
    display: flex;
    margin-top: 10px;
  }
  #project2{
    /* float: right; */
    margin-right: 10px;
  }
  #project3{
    /* float: right; */
    margin-right: 10px;
  }
  #project span {
    color: #5D6975;
    text-align: right;
    width: 52px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0.8em;
  }
  
  #company {
    float: right;
    text-align: right;
  }
  
  #project div,
  #company div {
    white-space: nowrap;        
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px;
  }
  
  .invoice-table tr:nth-child(2n-1) .invoice-td {
    background: #F5F5F5;
  }
  
  .invoice-table .invoice-th,
  .invoice-table .invoice-td  {
    text-align: center;
  }
  
  .invoice-table .invoice-th {
    padding: 5px 20px;
    color: #5D6975;
    border-bottom: 1px solid #C1CED9;
    white-space: nowrap;        
    font-weight: normal;
  }
  
  .invoice-table .service,
  .invoice-table .desc {
    text-align: left;
  }
  
  .invoice-table .invoice-td  {
    padding: 20px;
  }
  
  .invoice-table .invoice-td.service,
  .invoice-table .invoice-td.desc {
    vertical-align: top;
  }
  
  .invoice-table .invoice-td.unit,
  .invoice-table .invoice-td.qty,
  .invoice-table .invoice-td.total {
    font-size: 1.2em;
  }
  
  .invoice-table .invoice-td.grand {
    border-top: 1px solid #5D6975;;
  }
  #notices{
    margin-top: 10px;
  }
  #notices .notice {
    color: #5D6975;
    font-size: 1.2em;
  }
  
  .invoicefooter {
    color: #5D6975;
    width: 100%;
    height: 30px;
    bottom: 0;
    margin-top: 15px;
    border-top: 1px solid #C1CED9;
    padding: 8px 0;
    text-align: center;
  }