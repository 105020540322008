
card-body {
    min-height: 135px;
    padding: 1rem 1.5rem !important;
    border-top: 0;
    border-radius: 0;
}
.item-modifiers{
    color: #179145 !important;
    font-size: 18px;
    /* padding: 0.3rem 1rem; */
    font-family: Open Sans;
    font-weight: 700;
    /* line-height: 38px; */
    text-transform: uppercase;
}
.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    width: auto !important;
}
.sticky-button-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 31px;

    text-transform: uppercase;

    color: #FFFFFF;

}

.product-modal-title {
    font-family: Open Sans;
    padding: 17px 0 0 0;
    font-style: normal;
    font-weight: 700;
    font-size: 22px !important;
    text-transform: uppercase;
    color: #000000 !important;
}

.tortilla-btn {
    display: flex;
    padding: 0 15px;
    height: 36px;
    background-color: #fcaf17;
    color: #fff !important;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    -moz-box-pack: center;
    width: 50%;
    justify-content: space-evenly;
    -moz-box-align: center;
    align-items: center;
    text-transform: uppercase;
}

.tortilla-add-btn {
    display: flex;
    padding: 0 15px;
    height: 45px;
    color: #fff !important;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    text-transform: uppercase;
    border: none;
    cursor: pointer;


    /* Secondary */

    background-color: #8A2432;
    border-radius: 5px;
}

.top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

input[type=radio] {
    transform: scale(1.2);
}

input[type='radio']:after {
    width: 18px;
    height: 18px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 18px;
    height: 18px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #179145;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 3px solid white;
}

.col-left {
    width: 60%;
}

.col-right {
    width: 40%;
}

.inner-container {
    padding: 5px;
}

.closeIcon {
    background-color: #8a2432;
    color: white;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    min-width: 35px;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 8px;
    left: 9px
}

.mod-span-mobile {
    font-size: 15px;
    color: black;
    opacity: 0.8;
}

.mod-row-mobile {
    padding: 10px 0px;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
}

.mod-row-mobile:hover {
    background: #fafafa;
}

.item-quantity-row {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: right;
    background: #fff;

}

.tortilla-inc-icon {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    color: #8a2432 !important;
    background: #fff !important;
    border-width: 1px;
    --border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--border-opacity));
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 9999px;
    line-height: 2;
    font-family: Open Sans;
    text-align: center;
    border: 1px solid #e2e8f0;
}

.tortilla-inc-icon:hover {
    border: 1px solid #8a2432;
}

.singleSelect td {
    padding: 5px 20px;
    border: none;
}

.custom-radio-label {
    font-family: Open Sans;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: initial;
}

.drop-down {
    border-radius: 10px;
    border-color: gray;
    height: 42px;
}

.numericInput {
    padding-left: unset !important;
    padding-right: unset !important;
    pointer-events: none;
}

.numericInput:focus {
    pointer-events: none;
}

.addons-label {
    font-family: Open Sans;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: initial;
}

.modifier-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    max-height: 23px;
    padding-left: 3rem;
}

.decrement-btn {
    user-select: none;
    width: 26px;
    height: 24px;
    display: block;
    text-align: center;
    background-color: #fff;
    color: black;
    position: relative;
    /* font-size: 18px; */
    font-weight: bold;
    border: 2px solid gray;
    margin-left: 10px;
}

.increment-btn {
    user-select: none;
    width: 26px;
    height: 24px;
    display: block;
    position: relative;
    text-align: center;
    background-color: #8a2432;
    color: #fff;
    /* font-size: 12px; */
    font-weight: bold;
    margin-right: 10px;
}

.inc-icon {
    font-size: 23px;
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    text-align: center;
}

.dec-icon {
    font-size: xx-large;
    position: absolute;
    top: -16px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    text-align: center;
}

.card {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
}

.indexing {
    display: flex;
    justify-content: space-between;
}

.icon-indexing {
    background: #8a2432;
    height: 20px;
    width: 20px;
    text-align: center;
    color: #fff;
    margin-right: 10px;
}

.add-btn {
    cursor: pointer;
}

.added {
    border: 1px solid #8a2432;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #8a2432;
    font-weight: 500;
    text-align: center;
    width: 80px;
    height: 22px;
}

.notAdded {
    background: #8a2432;
    border: 1px solid #8a2432;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #fff;
    font-weight: 500;
    text-align: center;
    width: 80px;
    height: 22px;
}

.lt-item {
    display: flex;
    width: calc(100% - 100px);
    flex-wrap: wrap;
    justify-content: space-between;
}

/* .card-width {
    width: calc(50% - 8px);
} */
.card-width {
    width: 100%;
}

.cart-button {
    background-color: #8a2432 !important;
    color: #FFF !important;
    border-color: #FFF !important;
}

.tax-text {
    vertical-align: bottom;
    color: #777777;
}

input[type=checkbox] {
    -webkit-appearance: none;
    /* background-color: #752525; */
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
}

input[type=checkbox]:checked {
    background-color: #8820ce;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
    width: 20px;
    min-width: 20px;
    height: 20px;
}

input[type=checkbox]:checked::after {
    color: white;
    width: 20px;
    height: 20px;
    content: '✔';
    margin-left: 3px;
}

.required-label {
    font-family: Open Sans;
    display: inline-block;
    border-width: 1px;
    --text-opacity: 1;
    color: #888d9a;
    font-size: 1.3rem;
    line-height: 2.5rem;
    font-weight: 600;
    border-radius: 0.25rem;
    margin-right: -0.25rem;
}

.choose-label {
    display: inline-block;
    border-width: 1px;
    --text-opacity: 1;
    color: #888d9a;
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: 600;
    border-radius: 0.25rem;
    margin-right: -0.25rem;
}

.choose-label--error {
    color: #8a2432;
}

.required-label--error {
    color: #8a2432;
    padding: 0.25rem 0.5rem;
    border: 1px solid #8a2432;
}

.modal-dialog {
    max-width: 600px;
}

.modal-body {
    padding: 5px !important;
    max-height: 91vh;
    overflow: auto;
}

.stick-modal-bottom {
    background-color: white;
    bottom: 0;
    position: sticky;
}

.quantity-media-mobile {
    width: 100%;
    padding: 10px 0px;
}

@media screen and (min-width: 768px) {
    .card-media-mobile {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .marginBottom {
        margin-bottom: 1.2rem !important;
    }

    .card-media {
        display: none;
    }

    .card-media-mobile {
        display: none;
        width: 100%;
        padding: 10px 0px;
    }

    .required-label {
        display: inline-block;
        border-width: 1px;
        --text-opacity: 1;
        color: #888d9a;
        font-size: 1.3rem;
        line-height: 1.5;
        font-weight: 600;
        border-radius: 0.25rem;
        margin-right: -0.25rem;
    }

    .choose-label {
        display: inline-block;
        border-width: 1px;
        --text-opacity: 1;
        color: #888d9a;
        font-size: 1.3rem;
        line-height: 1.5;
        font-weight: 600;
        border-radius: 0.25rem;
        margin-right: -0.25rem;
    }

    .choose-label--error {
        color: #8a2432;
    }

    .required-label--error {
        color: #8a2432;
        padding: 0.25rem 0.5rem;
        border: 1px solid #8a2432;
        height: 25px;
    }
}

@media screen and (max-width: 750px) {
    .numericInput {
        padding: 2px;
    }
}

/* Modal CSS/ */

.coverImage {
    height: 30rem;
    position: relative;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    background-size: contain;
    background-position: 54%;
    background-repeat: no-repeat;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.75rem;
}

.tortilla_sticky_add_cart {
    display: flex;
    align-items: center;
    position: sticky;
    bottom: -5px;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: white;
    margin-top: 1rem;
}

.tortilla-sticky-add-btn {
    position: relative !important;
    color: white !important;
    border-radius: 5px !important;
    text-align: center !important;
    width: 100% !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    height: 45px !important;
    font-size: 22px;
    font-weight: 400 !important;
    line-height: 20px;
    text-transform: uppercase;
    border: none;
    font-family: Open Sans;
    cursor: pointer;
    width: 319px;
    height: 75px;
    background: #8A2432 !important;
}

.item-quantity-text {
    padding-top: 6px;
    font-weight: bold;
    font-size: 28px;
    width: 4rem;
    line-height: 24px;
    text-align: center;
    font-family: Open Sans;
    font-weight: 700;
    color: #0A0A0A;


}

.add-to-basket-btn-price {
    /* position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
    line-height: 1rem;
    line-height: 1.5;
    font-weight: 600; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 55px;
    /* identical to box height, or 130% */


    /* Secondary */

    color: #8A2432;

}
@media only screen and (min-width: 320px) and (max-width: 418px) {
    .tortilla-sticky-add-btn {
        position: relative !important;
        color: white !important;
        border-radius: 5px !important;
        text-align: center !important;
        width: 100% !important;
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
        height: 45px !important;
        font-size: 9px;
        font-weight: 700 !important;
        line-height: 20px;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        width: 319px;
        height: 75px;
        background: #8A2432 !important;
    
    }
    .item-quantity-text {
        padding-top: unset !important;
        font-weight: bold;
        font-size: 20px;
        width: 4rem;
        line-height: 24px;
        text-align: center;
        font-family: Open Sans;
        font-weight: 700;
        color: #0A0A0A;
    }
    .add-to-basket-btn-price {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 700;
        font-size: 14px !important;
        line-height: 55px;
        color: #8A2432;
    }
    .product-modal-title {
        font-family: Open Sans;
        padding: 17px 0 0 0;
        font-style: normal;
        font-weight: 700;
        font-size: 15px !important;
        text-transform: uppercase;
        color: #000000 !important;
    }
    
}

@media only screen and (min-width:320px) and (max-width: 768px) {
    .tortilla-add-btn {
        display: none;
    }

    .desktop-text {
        display: none;
    }

    /* .tortilla-sticky-add-btn {
        position: relative !important;
        background-color: #fcaf17 !important;
        color: white !important;
        border-radius: 8px !important;
        text-align: center !important;
        width: 100% !important;
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
        height: 45px !important;
        font-size: 14px;
        font-weight: 700 !important;
        line-height: 20px;
        text-transform: uppercase;
        border:none;
        cursor: pointer;
    }
    .add-to-basket-btn-price {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.2rem;
        line-height: 1rem;
        line-height: 1.5;
        font-weight: 600;
    } */
}
@media screen and (min-width:769px) and (max-width:3500px){
    .mobile-media{
        display: none;
    }
}