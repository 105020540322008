#round {
    border-radius: 10px;
    border-color: gray;
    height: 10px;
  }

  @media (max-width:915px) {
    .mobile-view-page-contactus{
      margin-top:80px
      /* position:relative;
      top:55px; */
  }
  body {
    font-size: 16px;
  }
  input[type="text"] {
    font-size: inherit;
  }
  .org-header__btn--circle{
      width:40px;
      height:40px;
  }
  }
  #round1 {
    border-radius: 10px;
    border-color: gray;
    height: 42px;
    width: 430px;
  }
  #round2 {
    border-radius: 10px;
    border-color: gray;
    height: 150px;
    width: 100%;
    resize: none;
  }
  
  textarea {
    padding: 6px 8px;
    line-height: 1.5;
    resize: none;
  }
  #txt {
    color: rgb(100, 99, 99);
  }
  
  .check-labled {
      margin-left : 10px;
      margin-bottom : 0;
      font-size : 20px;
      font-weight : normal;
  }

  .check-row {
    display: flex;
    flex-direction: column;
  }