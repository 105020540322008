#center{
    text-align: center;
    margin-top: 20px;
    color:#666
}
h2{
    font-family: Open Sans;
    font-style: normal;
    text-transform: uppercase;
    color: #000000;
}
.direction-btn{
    background-color: #8a2432 !important;
    color: white;
    width: 133px;
    padding: 10px;
    border-radius: 8px;
    margin-right: 5px;
}
h5{
    font-family: Open Sans;
    font-style: normal;
    /* text-transform: uppercase; */
    color: #000000;

}
@media (max-width:559px) {
    .mobile-view-page{
        /* margin-top:70px */
        position:relative;
        top:55px;
    }

    .org-header__btn--circle{
        width:40px;
        height:40px;
    }
    #info{
        text-align: center;
    }

    #txtcolor{
        display:flex;
        justify-content:center;
    }
  }
#txtcolor{
    color:#666;
    font-size: medium;
}

#info{
    font-size: medium;
}

#dropdownMenuButton{
    padding: 1.2rem 2.475rem;
    font-size: inherit;
    font-family: "Open Sans",sans-serif;
    width: 150%;
}
.stores-wrapper:hover {
    background-color: #eaeaea;
    padding: 1.2rem;
    cursor: pointer;
}
.dropdown {
    width: 100%;
}
.dropdown-select {
display: flex;
width: 100%;
padding: 1.5rem;
border: 1px solid #eaeaea;
justify-content: space-between;
align-items: center;
border-radius: 10px;
position: relative;
}

.dropdown-option-list {
position: absolute;
background-color: #fff;
z-index: 122;
width: 100%;
max-height: 200px;
overflow-y: scroll;
}

.dropdown-option-item {
    padding: 1.2rem;
}
.dropdown-option-item:hover {
    background-color: #8a2432;
    color: #fff;
}


@media only screen and (max-height: 991px){
#rowcolor {
    position: relative;
    }
}

#branches{ 
    height: 40%;
    font-size: 18px;
}
hr.new1 {
    margin: 0;
  }