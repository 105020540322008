#cookie-table {
    width: 100%;
    text-align: center;
    border: 1px solid rgb(41, 41, 41);
    border-collapse: collapse;
}

#cookie-table thead {
    background-color: #8a2432 !important;
    color: white
}

#cookie-table td {
    color: #7b858a;
}

#cookie-table th {
    border: 1px solid rgb(255,255,255,0.5)
}

#cookie-table tr, #cookie-table td {
    border: 1px solid rgb(41, 41, 41, 0.3)
}

#cookie-table td, #cookie-table th {
    padding: 15px;
}

.table-wrapper {
    overflow-x: scroll;
    white-space: wrap;
}

@media (max-width: 990px) {
    #cookie-table td {
        text-wrap: nowrap;
    }
}

#cookies p strong {
    font-size: 16px;
}