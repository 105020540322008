blockquote {
    position: relative;
    text-align: left;
    padding: 0 0 0em 38px;
    border: none;
    margin: 0px auto 0px;
    max-width: 800px;
    width: 100%;
    display: block;
}

.about-section-custom p{
    margin-bottom: 0;
}

.about-img {
    height:auto;
}




@media only screen and (max-width:1215px)
{
    .image-responsive{
        width:auto;
        margin: auto;
    }
} 

@media only screen and (min-width:1216px) and (max-width:3000px)
{
    .image-responsive{
        width: 80%;
        margin: auto;
    }
}

@media (max-width:820px) {
    .mobile-view-page{
        /* margin-top:70px */
        position:relative;
        top:77px;
    }

    .org-header__btn--circle{
        width:40px;
        height:40px;
    }
}

blockquote:after {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0;
    color: #8a2432;
    top: 0;
    background: -moz-linear-gradient(top,#8a2432 0%,#8a2432 60%,rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top,#8a2432 0%,#8a2432 60%,rgba(255,255,255,0) 100%);
    /* background: linear-gradient(to bottom,#950a1c 0%,#950a1c 60%,rgba(255,255,255,0) 100%); */
}

.quotation-mark {
    content:"\f10d";
    font-family: "fontawesome";
    font-size: 45px;
    display: block;
    font-weight: 400;
    color: #8a2432;
}

blockquote > cite, 
blockquote > p > cite {
    display: block;
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 700;
    font-style: normal;
    margin-top: 1.1em;
    letter-spacing: 0;
    font-style:italic;
}


