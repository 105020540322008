.dropdown-expanded li + li {
  margin-left: 1rem;
  margin-right: 1rem;
}
.header-dropdown ul a {
  display: block;
  padding: unset !important;
  color: #fff !important ;
  white-space: nowrap;
}
.custom-default-red-button {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  background-color: #981928 !important;
  border-radius: 30px !important;
  color: #fff !important;
  border-color: #fff !important;
}
.custom-default-red-button.btn-applye-halla {
  padding: 0 !important;
  border-radius: 0 !important;
  padding-inline: 10px !important;
}
.cart-dropdown .product-title a {
  color: #000000 !important;
  font-size: 16px;
}
.cart-dropdown .product-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  padding-right: 1.5rem;
  margin-bottom: 8px;
  font-size: 1.3rem !important;
  line-height: 19px;
  color: #000000 !important;
}
.cart-dropdown .product-title-arabic {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  padding-left: 4.5rem;
  margin-bottom: 8px;
  font-size: 1.3rem !important;
  line-height: 19px;
  color: #000000 !important;
}
.dropdown-cart-header {
  font-style: normal;
  /* font-weight: 700; */
  /* font-size: 2.8rem; */
  /* line-height: 54px; */
  text-transform: uppercase;
  color: #179145;
  border-bottom: 1px solid #e6ebee;
  font-size: 2rem;
  padding: 0.3rem 1rem;
  /* color: #474747; */
  font-family: Open Sans;
  font-weight: 700;
  line-height: 38px;
  text-transform: uppercase;
}

.dropdown-toggle:after {
  font-size: 17px;
}

.profilepic {
  border-radius: 51px !important;
  border: none !important;
  /* height: 57px !important; */
  /* width: 60px !important; */
  /* background-color: white; */
}

.sidenav__list {
  background-color: rgb(192, 10, 39) !important;
  margin-left: -15px;
}

.navbar__logo {
  display: none !important;
}

.track-order-style {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 150px;
}

.header-icon {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin: -3px 6px 0 4px !important;
}
.header-icon-arabic {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin: -3px 0 0px 55px !important;
}

.logo-margin {
  margin: 0 auto;
}

.header-bg-color {
  background-color: #8a2432;
}

.br-delivery {
  border-radius: 10px 0px 0px 10px;
}

.br-pickup {
  border-radius: 0px 10px 10px 0px;
}
.ar-rtl .br-pickup {
  border-radius: 10px 0px 0px 10px !important;
}
.ar-rtl .br-delivery {
  border-radius: 0px 10px 10px 0px !important;
}
.main-nav .menu > li > a {
  color: #ffd400;
}

.main-nav .menu > li.active > a,
.main-nav .menu > li > a:hover {
  border-top-color: #ffd400;
  border-top: 3px solid #ffd400;
  /* background-color: #ffd400; */
  /* color: #950a1c; */
}

.sidenav__list {
  background-color: rgb(192, 10, 39) !important;
  margin-left: -15px;
}

.navbar__logo {
  display: none !important;
}

.header-bg-color {
  background-color: #8a2432 !important;
}
.li-tag .header-link {
  color: #fff !important;
}
.header-link {
  color: #fff !important;
  /* font-weight: 500 !important; */
  font-size: 18px !important;
  /* font-size: 16px !important; */
  padding: 3px 4px !important;
  margin: 0px 5px !important;
  border-top: 2px solid transparent;
}

.modala {
  width: 400px !important;
  margin: auto !important;
  padding-top: 13%;
  z-index: 9999999 !important;
}
.register-link {
  color: white !important;
  border: 2px solid white;
  border-radius: 5px;
  font-size: 13px !important;
  padding: 6px 4px !important;
  margin: 0px 5px !important;
}

.header-link:hover,
.header-link:active {
  border-top: 2px solid #fff !important;
  transition: unset !important;
}

.main-nav .menu > li > a {
  color: #8a2432;
}

.main-nav .menu > li.active > a,
.main-nav .menu > li > a:hover {
  border-top-color: #8a2432;
  border-top: 3px solid #8a2432;
  /* background-color: #ffd400; */
  /* color: #950a1c; */
}

.cart-button {
  background-color: #8a2432 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.cart-button:hover {
  background-color: #8a2432 !important;
}

.social-icons {
  display: flex;
  align-items: center;
}

/* .font-color{
  background-color:#950a1c;
} */
/* #page-wrap {
  text-align: center;
  
  Prevent sidebar from showing a scrollbar on page
  overflow: auto;
} */

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 10em;
  top: 4.5em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 20px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffd400;
}

/* General sidebar styles */

.bm-menu {
  background: #8a2432;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #ffd400;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-item-list > a {
  padding: 10px;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px #ffd400;
  text-align: center;
  color: #ffd400 !important;
}

.bm-item-list > a:hover {
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px #fff;
  color: #fff !important;
}

.header-top {
  /* top: 50px !important; */
  color: #8a2432;
  /* position: absolute; */
  width: 100%;
  z-index: 123;
  border: none;
}
.halla-herder-top {
  position: fixed;
  top: 0px !important;
  z-index: 9;
  width: 100%;
}

.header-dropdown ul a {
  font-size: 1.2em;
  white-space: unset !important;
}

.header-side-divs {
  padding-top: 0.7rem;
}

.profile-cart-margin {
  margin: 0 0 0 auto;
}

.mobile-header-right-side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

/* .menu{
  padding-top: 0.7em;
} */
.custom-dropdown-btn-remove::after {
  content: none !important;
}

.header-left {
  font-size: 1.6em;
}

.sticky-header {
  background: #fff;
}

.sticky-header.fixed {
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  top: 0;
  padding: 0 !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08) !important;
  z-index: 1040 !important;
}

.middle-header-link {
  color: #ffd400 !important;
}

.middle-header-link:hover {
  border-top-color: #ffd400 !important;
  border-top: 3px solid #ffd400 !important;
  /* background-color: #ffd400; */
  /* color: #950a1c; */
}

.more-a-tag {
  font-size: 0.9em !important;
}

.fa-shopping-basket {
  color: white !important;
}

.tortilla-logo {
  font-size: 25px;
  font-weight: bold;
}

.header-logo-styling {
  width: 200px !important;
  margin: 0 auto;
}
.header-logo-styling.location-halla-logo {
  width: 32px !important;
}
.header-logo-styling-halla {
  width: 130px;
}
[dir="rtl"] .header-logo-styling {
  width: 11vw;
}

.checkbtn {
  color: white;
  font-size: 30px;
  float: right;
  text-align: center;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}

.botton-menu {
  background: #8a2432;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  top: 55px;
}

.bottom-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.li-tag {
  padding: 17px;
  text-align: center !important;
  font-size: 25px;
  font-weight: bold;
}

.scroll {
  overflow: hidden;
}

.icon-user,
.header-icon {
  color: white !important;
}

.menu-icon {
  width: 5vw;
  cursor: pointer;
}

.close-icon {
  color: #8a2432 !important;
  font-size: 5vw;
  cursor: pointer;
}

.custom-default-green-button {
  background-color: #179145 !important;
  color: #fff !important;
  left: 23px !important;
  top: -8px !important;
  padding: 1px;
  width: 18px;
  height: 18px;
  font-size: 10px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 601px) and (max-width: 768px) {
  
  .bm-burger-button{
    display: none;
  }
 
  .main-nav{
    display: block;
  }
  
  .header-top-mobile{
    display: none;
  }
  .header-top-web{
    display: block;
  }
} */

/* @media screen and (min-width: 769) and (max-width: 992) {
  .mobileSidebar{
    display: none !important;
  }
  .navbar{
      display: none !important;
  }   
  .main-nav{
      display: block !important;
  }
  
  .header-top-mobile{
    display: none;
  }
} */
/* @media screen and (min-width: 769) and (max-width: 992)  {
  .tortilla-logo{
    font-size: 20px !important;
    padding: 5px !important;
    font-weight: bold !important;
  }
  .header-logo-styling{
    max-width: 4em !important;
    margin: 0 auto !important;
  }
} */
.desktop_cart_icon {
  /* display: none; */
}
.dropdown-cart-action .btn {
  padding: 5px;
  font-size: 22px;
}
@media screen and (min-width: 1351px) {
  .header-logo-styling {
    margin: 0px;
    max-width: auto;
  }

  .header-logo-styling-pad {
    width: 120px;
  }

  .menu-icon {
    display: none;
  }

  .close-icon {
    display: none;
  }

  .navbar-right {
    display: none;
  }

  [dir="rtl"] .header-logo-styling {
    width: 11vw;
  }

  .header-top {
    padding: 0 !important;
    /* height: 120px; */
  }

  .custom-container {
    height: 93px;
  }

  .desktop_cart_icon {
    /* display: block; */
  }
}

@media screen and (min-width: 992px) and (max-width: 1350px) {
  .menu-icon {
    display: none;
  }

  .close-icon {
    display: none;
  }

  .navbar-right {
    display: none;
  }

  .header-logo-styling {
    width: auto;
    margin: 0px;
  }

  [dir="rtl"] .header-logo-styling {
    width: 11vw;
  }

  .header-top {
    padding: 0 !important;
    /* height: 93px; */
  }

  .custom-container {
    height: 93px;
  }

  .desktop_cart_icon {
    /* display: block; */
  }
}

@media only screen and (min-width: 560px) and (max-width: 991px) {
  .desktop_cart_icon {
    /* display: block; */
  }

  .menu-icon {
    /* background-color: white; */
    display: block;
    width: 3vw;
  }

  .close-icon {
    display: block;
    font-size: 4vw;
  }

  .navbar-right {
    display: block;
    font-size: 15px;
    color: #fff;
    opacity: 0.9;
  }

  .icon-user {
    font-size: 4rem !important;
  }

  .header-logo-styling {
    width: 22vw !important;
    margin: 0 auto;
  }

  [dir="rtl"] .header-logo-styling {
    width: 15vw;
  }

  .header-top {
    padding: 0 !important;
    height: 77px;
  }

  .custom-container {
    height: 75px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 559px) {
  .menu-icon {
    /* background-color: white; */
    display: block;
    width: 5vw;
  }

  .close-icon {
    display: block;
    font-size: 5vw;
  }

  .navbar-right {
    display: block;
    font-size: 15px;
    color: #fff;
    opacity: 0.9;
  }

  .header-logo-styling {
    width: 20vw !important;
    margin: 0 auto;
  }

  [dir="rtl"] .header-logo-styling {
    width: 20vw;
  }

  .icon-user {
    font-size: 3rem !important;
  }

  .header-top {
    padding: 0 !important;
    height: 55px !important;
  }

  .custom-container {
    height: 55px;
  }

  .botton-menu {
    top: 55px;
  }
}

.cart-dropdown-hover {
  animation: shake 0.9s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.dropdown-expanded .header-menu ul {
  transform: unset !important;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 320px) and (max-width: 440px) {
  .modala {
    width: 95% !important;
    margin: auto !important;
    padding-top: 40%;
    z-index: 9999999 !important;
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: auto !important;
  pointer-events: none;
}

.modal-header {
  padding-right: 3rem;
  padding-left: 3rem;
  height: 12rem;
  border-bottom: unset !important;
}

.modal-pickup-header .close {
  padding: 1rem;
  margin: -10rem -1rem 0rem auto !important;
}

.modal-pickup-header {
  padding-right: 3rem;
  padding-left: 3rem;
  height: 5rem;
  border-bottom: unset !important;
}

.modal-dialog {
  max-width: 650px;
}

.modal-footer2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-top: unset !important;
}

.modal-footer1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  /* height: auto; */
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-top: unset !important;
}

.modal-header .close {
  padding: 1rem;
  margin: -10rem -3rem -4rem auto !important;
}

.modal-address-content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #222720;
}

.modal-address-details {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #222720;
}

.modal-pickup-header {
  padding-right: 3rem;
  padding-left: 3rem;
  height: 43px;
  border-bottom: unset !important;
}

.modal-custom-style {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 100vh;
}

.modal-custom-style {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 100vh;
}
.halla-header__right {
  background-color: #8a2432 !important;
  justify-content: end;
  align-items: center;
  padding-block: 12px;
  padding-inline: 10px;
}
.halla-header__right.halla-sticky-header {
  position: fixed;
  width: 100%;
  top: 0;
}
.header-opacity-halla {
  opacity: 0;
}
.close-icon.halla-icon-mobile {
  color: #fff !important;
}
.halla-icon-mobile {
}
.halla-icon-mobile-flex {
  display: flex;
  justify-content: space-between;
}
.logo-update-mobile {
  display: none;
}
@media (max-width: 991px) {
  .logo-update-mobile {
    display: block;
  }
  .custom-default-red-button {
    width: 100%;
  }
}
