.halla-stores__images {
  /* width: 100px; */
}
.halla-stores-images h3 {
  font-size: 60px;
  color: #8a2432;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 200;
  font-style: italic;
}
.halla-stores-images a {
  background-color: #179145;
  padding: 2px 9px;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.view-all-menu {
  background-color: #8a2432;
  padding: 10px 70px;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}

.view-all-menu:hover {
  cursor: pointer;
}
.halla-stores-images {
  text-align: center;
}
.halla-stores-images img {
  height: 35rem;
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
}
.increased-icon-size {
  font-size: 44px !important; /* Adjust the size as needed */
}
.nex-arrow-slider-halla {
  position: absolute;
  right: 0;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
}

.previous-arrow-slider-halla {
  position: absolute;
  left: 0;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
}
.halla-store-slider-images {
  padding-block: 100px;
}
.halla-store-slider-images h4 {
  color: #8a2432;
  text-align: center;
  font-size: 81px;
  font-weight: 400;
}

.halla-store-slider-images h5 {
  color: #179145;
  text-align: center;
  font-size: 39px;
  font-weight: 400;
  margin-bottom: 0;
}
.halla-store-slider-images .slick-dots {
  bottom: -50px;
}
.mob-slider-add {
  display: none;
}
@media (max-width: 1024px) {
  .halla-store-slider-images .slick-arrow {
    display: none !important;
  }
  .mob-slider-add {
    display: block;
  }
  .web-slider-add {
    display: none;
  }
}
@media (max-width: 768px){
  .halla-store-slider-images h4{
    font-size: 40px;
  }
}