footer .widget-title {
  font-size: 22px;
  color: #8a2432;
  margin-bottom: 6px;
  font-weight: 500;
}

.footer .widget-newsletter .btn {
  padding: 8px 0.475rem;
  background-color: #8a2432;
  font-family: Open Sans;
  color: white;
  width: 44%;
  border-radius: unset;
  /* font-size: inherit; */
  /* font-family: "Open Sans",sans-serif; */
  border-radius: 3px;
  font-size: 22px;
  font-weight: 400;
  padding-block: 6px;
}

.links {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.customer-service-mob .text-left .links {
  color: rgb(152, 25, 40) !important;
  font-weight: 700;
  letter-spacing: 2px;
}

.text-h-footer {
  color: #4b4c4d !important;
}

.text-sh {
  color: #4b4c4d !important;
}

.bg-btn-nl {
  background-color: #ffd400 !important;
}

.bg-footer {
  background-color: #8a2432;
}

.footer-middle .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  /* fill: white; */
  /* background-color: #FFFFFF; */
  /* border: 1px solid #ccc; */
}

.footer-middle .copyright {
  margin-top: 15px;
  text-align: left;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.footer-middle .powered {
  margin-top: 15px;
  text-align: left;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.subscribe-btn:hover {
  background-color: #8a2432;
  color: #fff !important;
}

/* .twitter-btn:hover {
	background-color: #00acee;
	color: #FFF !important;
} */

.phone-btn:hover {
  /* background-color: #8a2432; */
  color: #fff !important;
}
.footer-links-news a:hover {
  color: #179145;
  border-bottom: 3px solid #179145;
}
a:hover,
a:focus {
  /* color: #fff !important; */
}

/* .facebook-btn:hover {
	background-color: #3b5998;
	color: #FFF !important;
} */

/* .insta-social{
	background: linear-gradient(45deg,
			#f09433 0%,
			#e6683c 25%,
			#dc2743 50%,
			#cc2366 75%,
			#bc1888 100%);
} */
.color-theme {
  color: rgb(152, 25, 40) !important;
}

.customer-service-mob {
  /* margin-left: 37%; */
}
.facebook-btn {
  background-color: rgb(59, 89, 152);
}
.icons-outer {
  font-size: 22px;
}
.instagram-btn {
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  color: #fff !important;
  font-size: 22px !important;
}
.join_our_news {
  border: none;
  font-size: 15px;
  width: 72%;
  padding: 6px 10px;
  border-radius: 7px;
  border: 1px solid #8a2432;
}

.halla-number {
  color: #179145;
  font-size: 20px;
}
.halla-number a:hover {
  color: #179145 !important;
}
.tictok-btn {
  background-color: #000;
}
.halla-social-icon-new {
  display: flex;
  justify-content: end;
  gap: 8px;
}
.footer-links-news {
  display: flex;
  gap: 15px;
}
.footer-links-news a {
  white-space: nowrap;
  font-size: 24px;
  letter-spacing: 0;
  padding-bottom: 3px;
  padding-inline: 10px;
}
.footer-copyright{
  font-family: 'Poppins' !important;
  letter-spacing: 0 !important;
}
@media (max-width: 767px) {
  .footer-mobile {
    justify-content: center !important;
  }
  .footer-middle .social > a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
  }
  .icons-outer {
    font-size: 20px;
  }
  .halla-social-icon-new {
    margin-block: 15px;
  }
  .customer-service-mob {
    margin-block: 20px;
  }
  .footer-links-news{
	flex-wrap: wrap;
	justify-content: center;
  }
}
@media screen and (max-width: 559px) {
  .footer-text-mobile {
    margin-right: 25px !important;
  }

  .footer {
    margin-top: 45px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .footer-logo-styling-mobile-view {
    width: 250px;
    /* margin: 0 0; */
  }

  .text-left {
    text-align: center !important;
  }

  .social {
    text-align: center !important;
  }

  .join_our_news-mob {
    text-align: center !important;
    margin: 0 10px;
  }

  .join_our_news {
    text-align: center !important;
    margin-left: unset;
  }
}

@media (min-width: 1222px) and (max-width: 2448px) {
  .footer-responsive {
    max-width: auto !important;
    /* margin-top: unset; */
    background-color: rgb(138, 36, 50);
    margin: auto !important;
  }
}

@media (max-width: 559px) {
  body {
    font-size: 16px;
  }

  input[type="text"] {
    font-size: inherit;
  }
}
