.location-heading{
    background-color: #8a2432;
    color: #fff;
    font-size: 81px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
    letter-spacing: 3px;
}
.location-map-store .input-search-location{
    background-color: #179145;
    width: 100%;
    padding: 20px 50px;
    text-align: center;
}
.location-map-store .input-search-location input{
    width: 100%;
    padding: 6px 10px;
    font-size: 18px;
    text-align: left;
}
.location-map-store button{
    background-color: #8a2432;
    border: none;
    padding: 2px 22px;
    font-size: 24px;
    color: #fff;
    border-radius: 3px;
    font-weight: 400;

}
.input-search-location h3{
   color: #fff; 
   font-size: 2.5rem;
   font-weight: 200;
}