.heading404{
    font-size: 120px;
  background: -webkit-linear-gradient(#8a2432, #fd7e14);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top:10%;
  text-align: center;
}

.heading-secondary{
    text-align: center;
    font-size: 30px;
}

.heading-third{
    text-align: center;
    font-size: 16px;
    color:#777
}

.btn404{
    background: -webkit-linear-gradient(#8a2432, #fd7e14);
    margin-bottom: 10px;
}