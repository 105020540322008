#scrollbar::-webkit-scrollbar {
  height: 10px;
  width: 10px;

  border: 2px solid #ffffff;
  background-color: #eee;
}

.web-view-cart {
  overflow-y: auto;
  height: 420px;
  overflow-x: hidden;
}

#scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(177, 174, 174);
  border-radius: 10px;
  border: 1px solid #d5d5d5;
}

.product-col .product-image img {
  border: unset !important;
}

.product-row {
  border: 1px solid #ddd !important;
  background: white !important;
  /* margin-bottom: 2rem; */
}

.table.table-cart tr td {
  padding: 4rem 1.5rem;
  border-top: none !important;
  font-size: 22px;
}

.inner-quickview figure .btn-quickview {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 8px;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .025em;
  text-transform: uppercase;
  visibility: hidden;
  opacity: 0;
  transform: none;
  margin: 0;
  border: none;
  transition: .25s;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  /* font-size: 14px; */
  background-color: #8a2432 !important;
  border-radius: 13px;
  color: #FFF !important;
  border-color: white
}

.cart-summary h3 {
  margin-bottom: 1.3rem;

  /* font-style: normal; */
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 15.9px !important;
  line-height: 26px;
  text-transform: uppercase;
  color: #000000;
}

tr td:not(.product-col) {
  font-style: normal;
  font-size: 20px;
  font-family: 'Open Sans';
  font-weight: bolder;
  color: #8a2432;
}

.cart-summary-text {
  font-style: normal;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 15.9px !important;
  line-height: 26px;
  text-transform: uppercase;
  color: #000000;
}

.table.table-cart tr th {
  padding: 2.4rem 1.5rem 1.2rem;
  border: 0;
  font-family: 'Open Sans';
  font-weight: 700;
  color: #179145 !important;
  font-size: 3rem;
}

.loginCart {
  width: 100%;
  background-color: #f6f6f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px 12px 35px;
}

.mrtop {
  margin-top: 10px;
}

.cartItemTitle .product-title {
  align-items: flex-start !important;
  font-weight: 700;
  font-family: "Open Sans";
}

.cartItemTitle .product-price {
  align-items: flex-start !important;
  font-weight: 700;
  font-family: "Open Sans";
  font-size: 15px;
}

.hert {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.backBtn-wrapper {
  width: 4rem;
}

.org-header__btn--circle {
  --bg-opacity: 1;
  background-color: #f5f5f5;
  background-color: rgba(245, 245, 245, var(--bg-opacity));
  margin-top: 10px;
  margin-bottom: 10px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  /* padding: 0.5rem; */
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  border-width: 1px;
  --border-opacity: 1;
  border-color: #f5f5f5;
  border-color: rgba(245, 245, 245, var(--border-opacity));
}
.ar-rtl .org-header__btn--circle{
  rotate: 180deg;
}
.org-header__btn--circle:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px -1px 20px 3px;
}

.loginCartButton {
  background-color: #8a2432;
  border: 0;
  color: #fff;
  font-size: 12px;
  padding: 6px 16px;
  box-shadow: none;
  text-transform: uppercase;
}

.deliverycart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px 12px 35px;
}

.pickupcart {
  color: #fff;
  font-size: 12px;
  /* padding:6px 16px; */
  box-shadow: none;
  text-transform: uppercase;
}

.iwant-sec {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  padding-bottom: 15px;
  border-bottom: 1px dotted #ddd;
}

.iwnt-toggleButton {
  margin: 0;
  display: flex;
  list-style: none;
  font-family: "open_sansregular", Arial, Helvetica, sans-serif !important;
  font-size: 15px;
  text-transform: uppercase;
}

.iwnt-toggleButton li {
  margin: 0 30px 0 0;
  padding: 5px 0 5px 0;
  display: flex;
  line-height: 1;
  align-items: center;
  cursor: pointer;
}

.cart-nowLater {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  margin-left: -10%;
}

.later-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-addressCart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
}

.name-deliveryCart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  align-content: center;
  padding: 20px;
  padding-top: 4%;
}

.storeName-cartD {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  background-color: #fff;
  align-content: center;
  padding-top: 4%;
  margin-top: -25px;
}

/* Dropdown Button */
.dropbtn {
  background-color: #8a2432;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
}

.storeShow-cart {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  color: white;
  background-color: #f3f1f9;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  /* width: 60%; */
}

.hideInputK {
  margin-top: -3%;
  display: flex;
  justify-content: space-around;
}

.notek {
  color: black;
  padding: 10px;
  width: 40%;
  padding-bottom: 50px;
}

.hideInputFieldK {
  width: 90%;
  height: 30%;
}

.hideInputFieldPickup {
  width: 40%;
  height: 40%;
  padding: 12px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.min-height {
  min-height: 35rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #8a2432;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #8a2432;
}

.cart-button {
  background-color: #8a2432 !important;
  color: #FFF !important;
  border-color: #FFF !important;
}

.cart-button:hover {
  background-color: #8a2432 !important;
}

.decrement-btn {
  user-select: none;
  width: 26px;
  height: 24px;
  display: block;
  text-align: center;
  background-color: #fff;
  color: black;
  position: relative;
  /* font-size: 18px; */
  font-weight: bold;
  border: 2px solid gray;
  margin-left: 10px;
}

.increment-btn {
  user-select: none;
  width: 26px;
  height: 24px;
  display: block;
  position: relative;
  text-align: center;
  color: #fff !important;
  border: 2px solid gray;
  /* font-size: 12px; */
  font-weight: bold;
  margin-right: 10px;

}

.inc-icon {
  font-size: 23px;
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.dec-icon {
  font-size: xx-large;
  position: absolute;
  top: -16px;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.special-ins {
  padding: 1rem !important;
  font-size: 14px;
}

.details-tab-content {
  background: beige;
  color: black;
  font-size: 14px;
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
}
.mt-low-halla{
  margin-top: 10px;
}
@media (max-width: 768px) and (min-width: 300px) {
  .noteK {
    width: 70%;
  }

  .hideInputFieldK {
    width: 70%;
    height: 15%;
  }

  #countResponsive {
    width: 40% !important;
    margin-top: -15%;
  }
}

.numericInputCart {
  padding: 0.5rem;
  height: unset !important;
}

.td-custom-padding {
  padding: 0.75rem !important;
}


@media (max-width: 765px) {
  .mt-low-halla{
    margin-top: 5px;
  }
  .col {
    flex-basis: unset !important;
  }
}

@media (max-width: 765px) {
  .min-height {
    min-height: 35rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .min-height {
    min-height: 60rem;
  }
}

@media (max-width:559px) {
  .mobile-view-page-cart {
    /* margin-top:70px */
    position: relative;
    top: 60px;
  }

  .org-header__btn--circle {
    width: 40px;
    height: 40px;
  }

  body {
    font-size: 16px;
  }

  input[type="text"] {
    font-size: inherit;
  }

  textarea {
    font-size: 16px;
  }

  .buttons-margin {
    margin: 6px;
  }
}

@media (min-width:320px) and (max-width:991px) {
  .web-view {
    display: none;
  }

  .web-top-items {
    display: none;
  }

  .mob-view {
    display: grid;
  }

  .mob-view-padding {
    padding-top: 20px;
  }

  .d {
    display: block !important;
  }
}

@media (min-width:992px) and (max-width:5000px) {
  .web-view {
    display: block;
  }

  .mob-view {
    display: none;
  }

  .mobile-top-items {
    display: none;
  }

  .d {
    display: flex !important;
  }


}