.sl-hds {
  font-size: 24px;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 15px;
  font-family: Open Sans !important;
  letter-spacing: normal;
}

.myButton {
  width: 100%;
}
.myButton:hover {
  background: linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
  background-color: #e4685d;
}
.myButton:active {
  position: relative;
  top: 1px;
}

.lgor {
  font-family: Open Sans !important;
  text-align: center;
  font-size: 15px;
  margin: 0 0 20px;
  border-bottom: 1px dotted #c7c7c7;
  width: 80%;
  display: table;
  font-style: normal;
}

.kep-login-facebook.metro {
  width: 100% !important;
  border-radius: 10px !important;
}

#roundb {
  border-radius: 10px;
}

.hellogoogle {
  width: 100%;
  height: 60px;
  border-radius: 10px;
}

element.style {
  text-align: center;
}
.cart-button{
  background-color: #c41000 !important;
  color: #FFF !important;
  border-color: #FFF !important;
}
.react-tel-input .form-control{
  border: 1px solid gray !important;
  border-radius: 10px !important;
  width: 100% !important;
  font-size: 22px !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid gray !important;
  border-radius: 10px 0 0 10px !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus
.react-tel-input{
  background-color: unset !important ;
}

.input-2 {
  margin-bottom: 1rem;
}

@media (max-width:559px){
  body {
    font-size: 16px;
  }
  input[type="text"] {
    font-size: inherit;
  }
}